$(function () {
  function getStoredState(elementId) {
    try {
      const stored = window.sessionStorage.getItem(getStorageKey(elementId));

      return stored ? JSON.parse(stored) : {};
    } catch (error) {
      return {};
    }
  }

  function saveState(elementId, state) {
    try {
      if (0 < Object.keys(state).length) {
        window.sessionStorage.setItem(getStorageKey(elementId), JSON.stringify(state));
      } else {
        window.sessionStorage.removeItem(getStorageKey(elementId));
      }
    } catch (error) {

    }
  }

  function getStorageKey(elementId) {
    return 'trendone_accordion_' + elementId;
  }

  $('[data-widget="accordion"]').each(function () {
    const $container = $(this);
    const $panes = $container.find('.ce-accordion__pane');
    const elementId = $container.attr('id');
    const state = getStoredState(elementId);

    function toggle(index, show, duration = 300) {
      const $pane = $panes.eq(index);
      const $content = $pane.find('.ce-accordion__pane-body');
      const $toggle = $pane.find('.ce-accordion__pane-toggle');
      const isAlreadyShown = !$toggle.next().attr('hidden');

      if (show === isAlreadyShown) {
        return;
      }

      $pane.toggleClass('ce-accordion__pane--open', show);
      if (show) {
        $content.attr('hidden', false).hide().slideDown(duration);
      } else {
        $content.slideUp(duration, function () {
          $content.attr('hidden', true);
        });
      }
      $toggle.attr('aria-expanded', show ? 'true' : 'false');
    }

    for (const [index, show] of Object.entries(state)) {
      toggle(index, show, 0);
    }

    $container.on('click', '[data-action="toggle"]', function (event) {
      const $toggle = $(event.currentTarget);
      const $pane = $toggle.closest('.ce-accordion__pane');
      const index = $panes.index($pane);

      const isHidden = !!$toggle.next().attr('hidden');

      toggle(index, isHidden);

      state[index] = isHidden;
      saveState(elementId, state);

      $toggle.blur();
    });
  });
});
