import $ from 'jquery';

$(function () {
  $('[data-widget="podcast-player"]').each(function () {
    const $container = $(this);
    const $embed = $container.find('[data-target="embed"]');

    $container.on('click', '[data-action="play"]', function (event) {
      $embed.attr('hidden', false).get(0).scrollIntoView();
      $(event.currentTarget).blur();
    });
  });
});
