import $ from '../vendor/jquery';

class MenuOverlay {
  constructor(element) {
    this.$container = $(element);
    this.$body = $('body');
    this.$toggle = $(this.$container.data('toggleSelector')).first();
  }

  initialize() {
    this._bindEventHandlers();
  }

  _showOverlay(menu = null, content = null) {
    const isVisible = this.$container.is(':visible');

    if (isVisible) {
      this._hideOverlay(0);
    }

    this.$container.fadeIn(isVisible ? 0 : 200);
    this.$toggle.attr('aria-expanded', 'true');
  }

  _hideOverlay(animationDuration = 200) {
    this.$container.fadeOut(animationDuration, () => {
      this.$toggle.attr('aria-expanded', 'false');
    });
  }

  _bindEventHandlers() {
    this.$toggle.on('click', (event) => {
      event.preventDefault();
      event.currentTarget.blur();

      this._showOverlay();
    });
    this.$container.on('click', '[data-action="open-sub-menu"]', (event) => {
      event.preventDefault();
      event.currentTarget.blur();

      const $link = $(event.currentTarget);

      $link.parent('.menu-overlay__item').toggleClass('menu-overlay__item--open');
      $link.next('.menu-overlay__items').slideToggle();
    });
    this.$container.on('click', '[data-action="close"]', () => {
      this._hideOverlay();
    });
    this.$body.on('click', (event) => {
      if (0 === this.$container.has(event.target).length && !this.$toggle.is(event.target)) {
        console.log(event.target);
        this._hideOverlay();
      }
    });
  }
}

$(function () {
  $('[data-widget="menu-overlay"]').each(function () {
    const instance = new MenuOverlay(this);
    instance.initialize();

    $(this).data('menuOverlay', instance);
  });
});
