import $ from '../vendor/jquery';

$(function () {
  $('body').on('click', '[data-action="show-element"]', function (event) {
    const $link = $(event.currentTarget);
    const $target = $($link.attr('href'));

    if ($target.length) {
      event.preventDefault();

      $link.slideUp(300, function () {
        $target.slideDown(300, function () {
          $link.remove();
        });
      });
    }
  });
});
