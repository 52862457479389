import { tns } from 'tiny-slider/dist/tiny-slider';

$(function () {
  $('[data-widget="slider"]').each(function () {
    const $element = $(this);
    const $info = $element.find('[data-target="info"]').first();
    const $controls = $element.find('[data-target="controls"]').first();
    const $itemsContainer = $element.find('[data-target="items"]').first();
    const $items = $itemsContainer.children();
    const $progress = $element.find('[data-target="progress"]').first();

    if (0 === $items.length) {
      return;
    }

    function onChange(info) {
      if (0 < $info.length) {
        const text = $info.data('template')
          .replace(/###CURRENT###/, info.displayIndex)
          .replace(/###TOTAL###/, info.slideCount);

        $info.html(text);
      }

      if (0 < $progress.length) {
        $progress.children().first().css('width', String((info.displayIndex + info.items - 1) * 100 / info.slideCount) + '%');
      }

      [...info.slideItems].forEach((element, index) => {
        if (index === info.index) {
          element.classList.add('slider__item--current');
        } else {
          element.classList.remove('slider__item--current');
        }
      });
    }

    $items.each(function (index, element) {
      const $info = $(element).find('[data-target="item-info"]').first();

      if (0 < $info.length) {
        const text = $info.data('template')
          .replace(/###CURRENT###/, index + 1)
          .replace(/###TOTAL###/, $items.length);

        $info.html(text).attr('hidden', false);
      }
    });

    const slider = tns({
      container: $itemsContainer.get(0),
      controls: 0 !== $controls.length,
      controlsContainer: $controls.get(0) ?? false,
      nav: $element.data('nav') ?? false,
      gutter: $element.data('gutter') ?? 20,
      items: $element.data('items') ?? 1,
      autoplay: $element.data('autoplay') ?? false,
      autoplayButtonOutput: false,
      autoplayTimeout: $element.data('autoplayTimeout') ?? 5000,
      lazyload: false,
      autoHeight: $element.data('autoHeight') ?? false,
      autoWidth: $element.data('autoWidth') ?? false,
      center: $element.data('center') ?? true,
      loop: $element.data('loop') ?? false,
      responsive: {
        500: {
          gutter: $element.data('gutter') ?? 30,
          items: $element.data('itemsSm') ?? 1,
        },
        769: {
          items: $element.data('itemsMd') ?? 1,
        },
        1240: {
          items: $element.data('itemsLg') ?? 1,
        },
        1800: {
          items: $element.data('itemsXl') ?? 1,
        },
        2000: {
          items: $element.data('itemsXxl') ?? 1,
        },
      },
      onInit: onChange
    });

    slider.events.on('indexChanged', onChange);

    $element.addClass('slider--active');
    $info.attr('hidden', false);
    $info.attr('hidden', 1 >= $items.length);

    $element.data('slider', slider);
  });
});
