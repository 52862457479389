$(function () {
  const $document = $(document);

  $('[data-widget="fields-of-activity"]').each(function () {
    const $slider = $(this);

    function closeAll() {
      $slider.find('.ce-fields-of-activity__overlay--open').removeClass('ce-fields-of-activity__overlay--open');
      $slider.find('.ce-fields-of-activity__overlay-toggle--open').removeClass('ce-fields-of-activity__overlay-toggle--open');
      $slider.find('.tns-outer').css('z-index', 0);
    }

    $slider.on('click', '[data-action="toggle-overlay"]', function (event) {
      const $link = $(event.currentTarget);
      const $target = $slider.find($link.attr('href'));

      if (0 < $target.length) {
        const isOpen = $target.hasClass('ce-fields-of-activity__overlay--open');

        closeAll();

        $target.toggleClass('ce-fields-of-activity__overlay--open', !isOpen);
        $target.prev().toggleClass('ce-fields-of-activity__overlay-toggle--open', !isOpen);

        if (!isOpen) {
          $slider.find('.tns-outer').css('z-index', 2);
        }

        $link.blur();

        event.preventDefault();
        event.stopPropagation();
      }
    });

    $slider.find('[data-widget="slider"]').data('slider').events.on('indexChanged', function () {
      closeAll();
    });

    $document.on('click', function (event) {
      if (0 === $(event.target).closest('.ce-fields-of-activity__overlay, .ce-fields-of-activity__overlay-toggle').length) {
        closeAll();
      }
    });

    $document.on('keyup', function (event) {
      if (27 === event.which) { // ESC
        closeAll();
      }
    });
  });
});
