import debounce from 'lodash.debounce';
import { tns } from 'tiny-slider/dist/tiny-slider';
import { setColorVariantModifier } from '../includes/color-variants';

$(function () {
  const $teasers = $('[data-widget="content-teaser"]');

  function toggleClasses() {
    $teasers.each(function () {
      const $teaser = $(this);

      $teaser.toggleClass('ce-content-teaser--large', (500 <= $teaser.width()));
    })
  }

  if (0 < $teasers.length) {
    toggleClasses();
    $(window).on('resize', debounce(toggleClasses, 100));

    $teasers.filter('.ce-content-teaser--slider').each(function () {
      const $element = $(this);
      const $items = $element.find('[data-target="items"]').first();
      const $progress = $element.find('[data-target="progress"]').first();
      const interval = 6000;
      let timeoutId = null;

      function resetProgress() {
        if (0 < $progress.length) {
          $progress
            .stop(true, true)
            .css({
              width: 0,
            })
            .animate({
              width: '100%',
            }, interval, 'linear');

          setColorVariantModifier($progress.get(0));
        }
      }

      function onChange(info) {
        const $slides = $(info.slideItems);
        const $slide = $slides.eq(info.index);

        if (timeoutId) {
          window.clearTimeout(timeoutId);
        }

        $slide.find('[data-aos]').addClass('aos-animate');
        if ('indexChanged' === info.type) {
          $slide.find('.ce-content-teaser__image')
            .stop(true, true)
            .css({
              opacity: 0,
            })
            .animate({
              opacity: 1,
            }, 700, 'linear');
        }

        resetProgress();

        timeoutId = window.setTimeout(function () {
          $slides.find('[data-aos]').removeClass('aos-animate');
          $slide.find('.ce-content-teaser__image')
            .animate({
              opacity: 0,
            }, 700, 'linear', function () {
              slider.goTo('next');
            });
        }, interval - 700);
      }

      const slider = tns({
        container: $items.get(0),
        mode: 'gallery',
        nav: true,
        controls: false,
        lazyload: false,
        loop: true,
        autoHeight: true,
        speed: 0,
        touch: false,
        onInit: onChange
      });

      slider.events.on('indexChanged', onChange);
    });
  }
});
