import $ from 'jquery';
import 'pikaday/plugins/pikaday.jquery'

$.ajaxSetup({
    cache: true,
});

window.$ = window.jQuery = $;

export default $;
