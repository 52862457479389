import 'picturefill';
import './vendor/jquery';
import './vendor/typo3';
import './includes/back-to-top';
import './includes/color-variants';
import './includes/klaro';
import './includes/forms';
import './includes/image-zoom';
import './includes/scroll-animations';
import './includes/show-element';
import './widgets/slider';
import './widgets/accordion';
import './widgets/click-to-enhance';
import './widgets/content-teaser';
import './widgets/fields-of-activity';
import './widgets/info-banner';
import './widgets/main-menu';
import './widgets/menu-overlay';
import './widgets/overlay';
import './widgets/parallax';
import './widgets/podcast-player';
import './widgets/procedure-slider';
import './widgets/show-more';
import './widgets/start-download';
import { registerCssBreakpointEvents } from './includes/utils';

registerCssBreakpointEvents();
