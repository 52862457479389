import './klaro-config';
import * as Klaro from 'klaro/dist/klaro-no-css';

window.klaro = Klaro;

[...document.querySelectorAll('[href="#cookie-banner"]')].forEach((el) => {
  el.addEventListener('click', (event) => {
    event.preventDefault();

    klaro.show();
  }, false);
});
