$(function () {
  const $banners = $('[data-widget="info-banner"]');

  $banners
    .prependTo('body')
    .attr('hidden', false)
    .on('click', '[data-action="close"]', function (event) {
      event.preventDefault();

      $(event.currentTarget).closest('[data-widget="info-banner"]').remove();
    });
});
