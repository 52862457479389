$(function () {
  $('[data-widget="click-to-enhance"]').each(function () {
    const $container = $(this);
    const $toggle = $container.find('[data-action="toggle"]');
    const $content = $container.find('[data-target="content"]');

    $toggle.on('click', function () {
      const isHidden = $content.attr('hidden');

      if (isHidden) {
        $content.attr('hidden', false).hide().slideDown(300);
        $toggle.children().text($toggle.data('textHide'));
      } else {
        $content.slideUp(300, function () {
          $content.attr('hidden', true);
        });
        $toggle.children().text($toggle.data('textShow'));
      }

      $toggle
        .toggleClass('click-to-enhance__toggle--show', !isHidden)
        .toggleClass('click-to-enhance__toggle--hide', isHidden)
        .blur();
    });
  });
});
