import $ from '../vendor/jquery';

class MainMenu {
  constructor(element) {
    this.$container = $(element);
    this.$body = $('body');
    this.$header = $(this.$container.data('headerSelector')).first();
  }

  initialize() {
    this._bindEventHandlers();
  }

  _closeSubMenus() {
    this.$container.find('.main-menu__item--open .sub-menu').slideUp();
    this.$container.find('.main-menu__item--open').removeClass('main-menu__item--open');
    this.$header.removeClass('header--dark');
  }

  _bindEventHandlers() {
    this.$container.on('click', '[data-action="open-sub-menu"]', (event) => {
      event.preventDefault();
      event.currentTarget.blur();

      const $link = $(event.currentTarget);
      const $subMenu = $link.next('.sub-menu');
      const alreadyOpen = $subMenu.is(':visible');

      this._closeSubMenus();
      if (!alreadyOpen) {
        $subMenu.slideDown();
        $link.parent('.main-menu__item').addClass('main-menu__item--open');
        this.$header.addClass('header--dark');
      }
    });

    this.$body.on('click', (event) => {
      if (0 === this.$container.has(event.target).length) {
        this._closeSubMenus();
      }
    });
  }
}

$(function () {
  $('[data-widget="main-menu"]').each(function () {
    const instance = new MainMenu(this);
    instance.initialize();

    $(this).data('mainMenu', instance);
  });
});
