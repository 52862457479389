import Rellax from 'rellax';

$(function () {
  const instances = [];

  $('[data-widget="parallax"]').each(function () {
    const speed = $(this).data('speed');

    const rellax = new Rellax(this, {
      speed: 'undefined' !== typeof speed ? speed : -2,
      center: true,
    });

    instances.push(rellax);
  });
});
