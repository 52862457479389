import $ from '../vendor/jquery';

$(function () {
  $('body').on('click', '[data-action="back-to-top"]', function (event) {
    window.scrollTo(0, 0);

    event.currentTarget.blur();

    event.preventDefault();
  });
});
