import $ from 'jquery';

$(function () {
  $('[data-widget="show-more"]').each(function () {
    const $container = $(this);
    const $elements = $container.find('[data-target="elements"]').children();
    const $toggle = $container.find('[data-target="toggle"]');
    const limit = $container.data('limit') ?? 8;

    if ($elements.length > limit) {
      $toggle.attr('hidden', false);
      $elements.slice(limit).hide();

      $container.on('click', '[data-action="show-all"]', function (event) {
        event.preventDefault();

        $toggle.attr('hidden', true);
        $elements.slideDown();
      });
    }
  });
});
