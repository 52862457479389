$(function () {
  $('[data-widget="start-download"]').each(function () {
    const $widget = $(this);
    const downloadUri = $widget.data('downloadUri');
    const redirectUri = $widget.data('redirectUri');

    if (downloadUri) {
      window.setTimeout(function () {
        window.location.href = downloadUri;

        if (redirectUri) {
          window.setTimeout(function () {
            window.location.href = redirectUri;
          }, 5000);
        }
      }, 100);
    }
  });
});
