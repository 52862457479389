import $ from 'jquery';
import 'magnific-popup';

$(function () {
  $('[data-widget="overlay"]').each(function () {
    const $overlay = $(this);
    const delay = $overlay.data('delay') || 2000;

    window.setTimeout(function () {
      $.magnificPopup.open({
        items: {
          src: $overlay,
          type: 'inline',
        },
      });

      $overlay.one('click', 'a', function () {
        $.magnificPopup.close();
      });
    }, delay);
  });
});
