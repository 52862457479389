import Aos from 'aos';

Aos.init();
window.addEventListener('load', function () {
  Aos.refresh();

  // Workaround for a bug in Firefox
  window.setTimeout(function () {
    Aos.refresh();
  }, 2000);
});
