import $ from '../vendor/jquery';

const NUMBER_OF_VARIANTS = 16;
const ELEMENTS = [
  ['button'],
  ['button-wrapper', '.button'],
  ['link'],
  ['rte', 'a'],
  ['record-list', 'a'],
  ['form__control', 'input, textarea', 'focus'],
  ['click-to-enhance__toggle--button'],
  ['ce-accordion__pane-toggle'],
];
const EVENT_NS = '.trendoneColorModifier';

function getRandomColorVariant() {
  return Math.round(Math.random() * (NUMBER_OF_VARIANTS - 1) + 1);
}

function setColorVariantModifier(element, className = null, colorVariant = null) {
  if (null === className) {
    className = 0 < element.classList.length ? element.classList[0] : 'color';
  }

  if (null === colorVariant) {
    colorVariant = getRandomColorVariant();
  }

  const classNameRegex = new RegExp(`^${className}--color-variant-\\d+$`);

  element.classList.forEach((current) => {
    if (classNameRegex.test(current)) {
      element.classList.remove(current);
    }
  });

  element.classList.add(`${className}--color-variant-${colorVariant}`);
}

function initializeColorVariantModifier(container = 'body') {
  ELEMENTS.forEach(function (item) {
    const className = item[0];
    const selector = item[1] || null;
    const eventName = (item[2] || 'mouseenter') + EVENT_NS;

    $(container).find(`.${className}`)
      .off(eventName)
      .on(eventName, selector, function (event) {
        setColorVariantModifier(event.delegateTarget, className);
      });
  });

  $(container)
    .off('mouseenter' + EVENT_NS)
    .on('mouseenter' + EVENT_NS, '.button-wrapper', function (event) {
      const $element = $(event.currentTarget);

      if ($element.find('.button')) {
        $element.find('.button').triggerHandler('mouseenter');
      }
    });
}

$(function () {
  initializeColorVariantModifier();
});

export { getRandomColorVariant, setColorVariantModifier, initializeColorVariantModifier };
